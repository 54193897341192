import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <div className="row">
            <div className="col-12">
              <div className="site-head-center">
                <Link className="site-head-logo" to={`/`}>
                  {title}
                </Link>
              </div>
              <a
                className="nav-burger"
                href={`#`}
                onClick={() => setToggleNav(!toggleNav)}
              >
                <div
                  className="hamburger hamburger--collapse"
                  aria-label="Menu"
                  role="button"
                  aria-controls="navigation"
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner" />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="navigation-bar">
            <nav id="swup" className="site-head-left">
              <ul className="nav" role="menu">
                <li className="nav-elements" role="menuitem">
                  <Link to="/connection-coaching">Connection Coaching</Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to="/yoga-dance">Yoga & Dance</Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to="/the-movement-challenge">
                    The Movement Challenge
                  </Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to="/modeling">Modeling</Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to="/#about">About Me</Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to="/#events">Events</Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to="/#connect">Connect</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> &mdash;
        Built with <a href="https://zencephalon.com/iluvu">luv</a>
      </footer>
    </div>
  )
}

export default Layout
